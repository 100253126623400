jQuery( window ).resize(function() {
	var fixed_area = jQuery('.fixed').outerHeight(true) ;
	//jQuery( ".fixed_start" ).height(fixed_area);
});

jQuery( document ).ready(function(){
	jQuery('.fixed').addClass('affix');
	var fixed_area = jQuery('.fixed').outerHeight(true) ;
	//jQuery('.fixed_start').height(fixed_area);
});


function updateHeader(area) {
	let fixedArea = area.getBoundingClientRect().height
	document.documentElement.style.setProperty('--headerHeight', fixedArea + 'px')
}

function footerHeight() {
	const footer = document.querySelector('footer')
	let footerArea = footer.getBoundingClientRect().height
	document.documentElement.style.setProperty('--footerHeight', footerArea + 'px')
}

{
	const fixed = document.querySelector('.fixed')
	fixed.classList.add('affix')
	updateHeader(fixed)
	footerHeight()

	window.addEventListener('resize', () => {
		updateHeader(fixed)
		footerHeight()
	})	
}