jQuery('.slider').slick({
	infinite: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	dots:true,
	arrows : false,
	autoplay : true,
	autoplaySpeed: 6000,
	pauseOnHover: false,
	pauseOnFocus: false,
});


jQuery('.services_carousel').slick({
	infinite: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	dots: false,
	arrows : false,
	autoplay : true,
	autoplaySpeed: 6000,
	pauseOnHover: true,
	pauseOnFocus: true,
	variableWidth: true,
	centerMode: true,
	centerPadding: '5px',
});

jQuery('.box_carousel').slick({
	infinite: true,
	slidesToShow: 1,
	dots: false,
	arrows : false,
	autoplay : false,
	autoplaySpeed: 6000,
	pauseOnHover: true,
	pauseOnFocus: true,
	variableWidth: true,
	centerMode: false,
	swipeToSlide: true,
});

jQuery('.team_carousel').slick({
	infinite: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	dots: false,
	arrows : false,
	autoplay : true,
	autoplaySpeed: 6000,
	pauseOnHover: true,
	pauseOnFocus: true,
	variableWidth: true,
	centerMode: true,
	centerPadding: '5px',
});

jQuery('.projects_carousel').slick({
	infinite: true,
	slidesToShow: 1,
	dots: false,
	arrows : false,
	autoplay : true,
	autoplaySpeed: 6000,
	pauseOnHover: true,
	pauseOnFocus: true,
	variableWidth: true,
	centerMode: true,
	centerPadding: '5px',
});

jQuery('.logo_gallery').slick({
	infinite: true,
	slidesToShow: 5,
	slidesToScroll: 1,
	dots: false,
	arrows : false,
	autoplay : true,
	autoplaySpeed: 3000,
	pauseOnHover: false,
	pauseOnFocus: false,
	variableWidth: true,
});

jQuery('.testimonials').slick({
	infinite: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	dots: true,
	arrows : false,
	autoplay : true,
	fade: true,
	autoplaySpeed: 12000,
	pauseOnHover: true,
	pauseOnFocus: true,
});

const slickNav = document.querySelectorAll('.carousel-nav')
if(slickNav) {
	slickNav.forEach( (nav) => {
		nav.addEventListener("click", () => {
			let slider = nav.dataset.slick,
				dir = nav.dataset.dir
			
			let slickDir = (dir === 'prev') ? 'slickPrev' : 'slickNext'

			jQuery('.' + slider).slick(slickDir)
		})
	})
}