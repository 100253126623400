class FormControls {
	constructor(form) {
		this.formContainer = form
		if(!this.formContainer) return

		this.selects = this.formContainer.querySelectorAll('select')

		this.init()
	}

	disableSelectLabel() {
		this.selects.forEach( (select) => {
			const options = select.querySelectorAll('option'),
				first = options[0]
			first.setAttribute('disabled', 'disabled')
		})
	}

	init() {
		this.disableSelectLabel()
	}
}

let forms = document.querySelectorAll('.acf-form')
forms.forEach( (form) => {
	new FormControls(form)
})